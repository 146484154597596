import { giftCartReducer } from "./slices/giftCardSlice";
import { combineReducers } from "redux";
import {
    tableReducer,
    settingReducer,
    shopReducer,
    fuSoftReducer,
    paymentReducer,
    storeReducer,
    cartReducer,
    guestReducer,
    marketReducer,
    userReducer,
} from "./slices";

const rootReducer = combineReducers({
    guestReducer,
    cartReducer,
    tableReducer,
    settingReducer,
    shopReducer,
    giftCartReducer,
    fuSoftReducer,
    paymentReducer,
    storeReducer,
    marketReducer,
    userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
