import { createSlice } from "@reduxjs/toolkit";
import { NPaymentSetting } from "../../interfaces/components/payment-setting";
import { RootState } from "../reducers";

type IPayment = NPaymentSetting.IPaymentMethodResponse;

const initialState = {
    paymentMethod: "",
    paymentProvider: "",
    serviceProvider: "",
    provider: "",
} as unknown as IPayment;

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        addPaymentMethod: (state, action) => {
            state = action.payload;
            return state;
        },
        removePaymentMethod: () => {
            return { ...initialState };
        },
    },
});

export const paymentReducer = paymentSlice.reducer;
export const paymentSelector = (state: RootState): IPayment =>
    state.paymentReducer;
export const { addPaymentMethod, removePaymentMethod } = paymentSlice.actions;
