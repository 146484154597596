import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { NStore } from "@interfaces/components/store";
import { RootState } from "../reducers";

type StoreState = NStore.IInfo;
const initialState = {} as NStore.IInfo;

const storeSlice = createSlice({
    name: "store",
    initialState,
    reducers: {
        selectStore: (state, action) => {
            const currentState = cloneDeep(state);
            currentState.host = action.payload?.host;
            return currentState;
        },
    },
});
export const storeReducer = storeSlice.reducer;
export const storeSelector = (state: RootState): StoreState =>
    state.storeReducer;

export const { selectStore } = storeSlice.actions;
