import axios, { AxiosInstance } from "axios";

import Response from "../../data-access/response/response";

import { notification } from "antd";
import { getCookie } from "cookies-next";

import { env } from "next-runtime-env";

export type ApiInstanceType = "default" | "market" | "geo" | "report";
export default class BaseApi {
    private httpClient: AxiosInstance;

    constructor(apiType?: ApiInstanceType) {
        const baseUrl = this.getBaseUrl(apiType);
        this.httpClient = axios.create({
            baseURL: baseUrl,
            withCredentials: false,
            timeout: 60 * 1000,
        });
        this.httpClient.interceptors.request = axios.interceptors.request;
        this.httpClient.interceptors.request.use((request) => {
            if (request.headers && !request.headers["Authorization"]) {
                const token = getCookie("access_token");
                request.headers["Authorization"] = `Bearer ${token}`;
            }

            return request;
        });
    }

    private getBaseUrl(apiType?: ApiInstanceType) {
        let url: string | undefined = env("NEXT_PUBLIC_API_URL");
        if (apiType === "market") {
            url = env("NEXT_PUBLIC_MARKET_API_URL");
        } else if (apiType === "geo") {
            url = env("GEO_ENDPOINT");
        } else if (apiType === "report") {
            url = env("NEXT_PUBLIC_MARKET_REPORT_API_URL");
        }
        return url;
    }

    public async get<T>(
        endPoint: string,
        params?: {
            [param: string]: string | string[];
        },
        headers?: any,
    ): Promise<Response<T | null>> {
        return this.httpClient
            .get<T>(endPoint, { params: params, headers })
            .then((response: any) => {
                const result = response.data;
                return new Response(true, result, "Success", "");
            })
            .catch((error: any) => {
                const errCode = error.response?.data?.error;
                return new Response(false, null, "Error", errCode);
            });
    }

    public async delete<T>(
        endPoint: string,
        params?: {
            [param: string]: string | string[];
        },
        headers?: any,
    ): Promise<Response<T | null>> {
        return this.httpClient
            .delete<T>(`${endPoint}`, { params: params, headers })
            .then((response: any) => {
                const result = response.data;
                return new Response(true, result, "Success", "");
            })
            .catch((error: any) => {
                const errCode = error.response?.data?.error;
                return new Response(false, null, "Error", errCode);
            });
    }
    public async post<T>(
        endPoint: string,
        data: Record<string, any>,
        params?: {
            [param: string]: string | string[];
        },
        headers?: any,
    ): Promise<Response<T | null>> {
        return this.httpClient
            .post<T>(`${endPoint}`, data, { params: params, headers })
            .then((response: any) => {
                const result = response.data;
                return new Response(true, result, "Success", "");
            })
            .catch((error: any) => {
                const errCode = error.response?.data?.error;

                return new Response(
                    false,
                    error?.response?.data,
                    "Error",
                    errCode,
                );
            });
    }

    public async put<T>(
        endPoint: string,
        data: Record<string, any>,
        params?: {
            [param: string]: string | string[];
        },
        headers?: any,
    ): Promise<Response<T | null>> {
        return this.httpClient
            .put<T>(`${endPoint}`, data, { params: params, headers })
            .then((response: any) => {
                const result = response.data;
                return new Response(true, result, "Success", "");
            })
            .catch((error: any) => {
                const errCode = error.response?.data?.error;
                return new Response(
                    false,
                    error?.response?.data,
                    "Error",
                    errCode,
                );
            });
    }

    public createSuccessNotification(
        successMessage: string | React.ReactNode,
    ): void {
        notification.success({
            className: "success-notification",
            message: "",
            description: successMessage,
        });
    }

    public createErrorNotification(
        errorMessage: string | React.ReactNode,
    ): void {
        notification.error({
            className: "error-notification",
            message: "",
            description: errorMessage,
        });
    }
}

export const makeFormData = (params: any) => {
    const formData = new FormData();
    for (const prop in params) {
        if (params[prop] === null || params[prop] === undefined) {
            continue;
        }
        if (Array.isArray(params[prop])) {
            const arr = params[prop] as string[];

            arr.forEach((value) => {
                formData.append(prop, value);
            });
        } else {
            formData.append(prop, params[prop]);
        }
    }
    return formData;
};
