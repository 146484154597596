import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SHOP } from "../../common/constant";
import { NShop } from "../../interfaces/components/shop";
import { ShopService } from "../../services/api/shop.service";
import { setSessionKeyValue } from "../../utils";
import { RootState } from "../reducers";

const initialState: NShop.IPublicShop = {
    id: "",
    stateId: "",
    state: "",
    cityId: "",
    city: "",
    postalCode: "",
    country: "",
    name: "",
    phoneNumber: "",
    fromHour: "",
    toHour: "",
    orderStoppageTime: 0,
    location: null,
    isPriceIncludedTax: true,
    setting: null,
    image: "",
};

const shopService = ShopService.getInstance();

export const getShopDetail = createAsyncThunk(
    "shopDetail",
    async (id: string, thunkApi) => {
        try {
            const result: any = await shopService.getPublicShop(id);

            return result;
        } catch (err) {
            return thunkApi.rejectWithValue(null);
        }
    },
);

const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        setShop: (state, action) => {
            setSessionKeyValue(SHOP, action.payload);

            state = action.payload;

            return state;
        },

        setOpeningTime: (state, action) => {
            if (action.payload) {
                delete action.payload.id;
                state = { ...state, ...action.payload };
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getShopDetail.fulfilled, (state, action: any) => {
            setSessionKeyValue(SHOP, action?.payload);
            return action?.payload || null;
        });
    },
});

export const shopReducer = shopSlice.reducer;
export const shopSelector = (state: RootState): NShop.IPublicShop =>
    state.shopReducer;
export const { setShop, setOpeningTime } = shopSlice.actions;
