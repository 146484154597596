import { env } from "next-runtime-env";

export const getPublicMediaUrl = (url: string): string => {
    if (url) {
        return `${env("NEXT_PUBLIC_CDN")}/${url}`;
    }
    return `${url}`;
};
export const getSessionValue = <T>(key: string): T | null => {
    if (typeof window !== "undefined") {
        const value = sessionStorage.getItem(key);
        if (value) return JSON.parse(value);
    }

    return null;
};
export const setSessionKeyValue = <T>(key: string, value: T): void => {
    sessionStorage.setItem(key, JSON.stringify(value));
};
export const removeSessionKeyValue = (key: string): void => {
    sessionStorage.removeItem(key);
};
