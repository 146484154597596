import { createSlice } from "@reduxjs/toolkit";
import { IGiftCard } from "../../interfaces/components/gift-card";
import { RootState } from "../reducers";

type IGiftCartState = IGiftCard.IReduxPayload;
const initialState = {
    orderId: "",
    grandTotal: 0,
    tipAmount: 0,
    giftCardAmount: 0,
    giftCardGroups: [],
} as IGiftCartState;

const giftCartSlice = createSlice({
    name: "giftCart",
    initialState,
    reducers: {
        addToGiftCart: (state, action) => {
            if (action.payload.balance === 0) {
                const remainGiftCardGroups = state.giftCardGroups.filter(
                    (item) =>
                        item.giftCardCodeId !== action.payload.giftCardCodeId,
                );

                state.giftCardGroups = remainGiftCardGroups;
                state.giftCardAmount = remainGiftCardGroups.reduce(
                    (total, item) => total + item.balance,
                    0,
                );

                return;
            }
            const itemInGiftCart = state.giftCardGroups.find(
                (item) => item.giftCardCodeId === action.payload.giftCardCodeId,
            );

            if (itemInGiftCart) {
                state.giftCardAmount +=
                    action.payload.balance - itemInGiftCart.balance;
                itemInGiftCart.balance = action.payload.balance;
            } else {
                state.giftCardGroups.push({
                    ...action.payload,
                });
                state.giftCardAmount += action.payload.balance;
            }
        },
        removeGiftCart: (state) => {
            state.orderId = "";
            state.grandTotal = 0;
            state.giftCardAmount = 0;
            state.giftCardGroups = [];
        },
        setGiftCart: (state, action) => {
            state = action.payload;
            return state;
        },
        setTipAmount: (state, action) => {
            state.tipAmount = action.payload;
        },
    },
});
export const giftCartReducer = giftCartSlice.reducer;
export const giftCartSelector = (state: RootState): IGiftCartState =>
    state.giftCartReducer;
export const { addToGiftCart, removeGiftCart, setGiftCart, setTipAmount } =
    giftCartSlice.actions;
