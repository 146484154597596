import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TABLE } from "../../common/constant";
import { NTable } from "../../interfaces/components/table";
import { TableService } from "../../services/api/table.service";
import { setSessionKeyValue } from "../../utils";
import { RootState } from "../reducers";

const initialState: NTable.ITable = {
    id: null,
    name: null,
    groupId: null,
    shopId: null,
};
const tableService = TableService.getInstance();

export const getTableDetail = createAsyncThunk(
    "tableDetail",
    async (id: string, thunkAPI) => {
        try {
            const result: any = await tableService.getTable(id);
            if (result?.data) {
                setSessionKeyValue(TABLE, result?.data);
            }
            return result?.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(null);
        }
    },
);

const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        removeTable: () => initialState,
        setTable: (_, action: PayloadAction<NTable.ITable>) => {
            setSessionKeyValue(TABLE, action?.payload);
            return action?.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTableDetail.fulfilled, (_, action: any) => {
            return action?.payload || null;
        });
    },
});

export const tableReducer = tableSlice.reducer;
export const tableSelector = (state: RootState): NTable.ITable =>
    state.tableReducer;
export const { removeTable, setTable } = tableSlice.actions;
