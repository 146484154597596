import { NCart, NProduct } from "@interfaces/index";

export const getLinkedProductNames = (
    productGroups?: (NProduct.ILinkedProductGroups | undefined)[],
) => {
    const names: string[] = [];
    productGroups?.forEach((group) => {
        if (group) {
            const { qty, linkedProducts } = group;
            if (qty > 0) {
                linkedProducts.forEach(({ name, qty }) => {
                    if (qty > 0) {
                        names.push(name);
                    }
                });
            }
        }
    });

    return names.join(", ");
};

export const getOptionsNames = (
    groupOptions?: (NCart.ISelectOptionType | undefined)[],
) => {
    const names: string[] = [];
    groupOptions?.forEach((group) => {
        if (!group) return;
        const { name: groupName, options } = group;
        const optionNames: string = options?.map((o) => o.name)?.join(", ");
        if (!optionNames) return;
        names.push([groupName, optionNames].join(" "));
    });
    return names.join(", ");
};
