import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NSetting } from "../../interfaces/components/setting";
import { SettingService } from "../../services/api/setting.service";
import { getPublicMediaUrl } from "../../utils/resource";
import { RootState } from "../reducers";

const initialState: NSetting.IGeneralInfo = {
    id: "",
    code: "",
    branchName: "",
    registrationDate: "",
    currency: "",
    isPriceIncludedTax: true,
    currencyConfig: null,
    welcomeMessage: "",
    giftCardModuleActive: false,
    loading: true,
    isBlockedSite: false,
};
const settingSerice = SettingService.getInstance();

export const getSetting = createAsyncThunk("setting", async (_, thunkAPI) => {
    try {
        const result = await settingSerice.getSetting();
        if (result?.banner) {
            result.banner = getPublicMediaUrl(result?.banner);
        }
        if (result?.bannerForDesktop) {
            result.bannerForDesktop = getPublicMediaUrl(
                result?.bannerForDesktop,
            );
        }

        return result;
    } catch (err: unknown) {
        const error = err as { message: string };
        if (error.message === "site_is_blocked") {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
        return thunkAPI.rejectWithValue(null);
    }
});

const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setStateGiftCardModule: (state, action) => {
            const status = action.payload;
            state.giftCardModuleActive = status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSetting.fulfilled, (state, action: any) => {
            return action?.payload
                ? {
                      ...state,
                      ...action.payload,
                      giftCardModuleActive: state.giftCardModuleActive,
                      loading: false,
                  }
                : {
                      ...state,
                      giftCardModuleActive: state.giftCardModuleActive,
                      loading: false,
                  };
        });

        builder.addCase(getSetting.rejected, (state, action: any) => {
            if (action.payload?.error === "site_is_blocked") {
                return { ...state, isBlockedSite: true, loading: false };
            }
            return { ...state };
        });
    },
});

export const settingReducer = settingSlice.reducer;
export const settingSelector = (state: RootState): NSetting.IGeneralInfo =>
    state.settingReducer;
export const { setStateGiftCardModule } = settingSlice.actions;
