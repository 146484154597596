import { OrderItemTypeEnum } from "@enums/order-item-type.enum";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { removeSessionKeyValue, setSessionKeyValue } from "@utils/resource";
import { cloneDeep } from "lodash";
import { CART } from "src/common/constant";
import { NCheckout } from "../../interfaces";
import { NCart } from "../../interfaces/components/cart";
import { OrderService } from "../../services/api/order.service";
import { RootState } from "../reducers";

type ICartState = NCart.ICart;
const initialState = {
    items: [],
    note: "",
    subTotal: 0,
    grandTotal: 0,
    taxAmount: 0,
    extraFee: 0,
    tipAmount: 0,
    orderId: "",
    discountAmount: 0,
    discountIds: [],
    giftCardAmount: 0,
    giftCardGroups: [],
    orderItemType: OrderItemTypeEnum.PRODUCT,
} as ICartState;

const orderService = OrderService.getInstance();

export const calcGiftCard = createAsyncThunk(
    "calcGiftCard",
    async (request: NCheckout.ICalcGiftCard, thunkAPI) => {
        try {
            const result = await orderService.calcGiftCard(request);

            return result;
        } catch (err) {
            return thunkAPI.rejectWithValue(null);
        }
    },
);

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const currentCart = cloneDeep(state);
            const itemInCart = currentCart.items.find(
                (item) => item.id === action.payload.id,
            );

            if (itemInCart) {
                itemInCart.qty += action.payload.qty;
            } else {
                currentCart.items.push({
                    ...action.payload,
                    qty: action.payload?.qty || 1,
                });
            }
            currentCart.shouldRefreshCart = true;
            return currentCart;
        },
        incrementQuantity: (state, action) => {
            const currentCart = cloneDeep(state);
            const item = currentCart.items.find(
                (item) => item.id === action.payload.id,
            );
            if (item) {
                item.qty += action.payload.qty;
            }
            currentCart.shouldRefreshCart = true;
            return currentCart;
        },
        decrementQuantity: (state, action) => {
            const item = state.items.find((item) => item.id === action.payload);

            if (!item) {
                return;
            }

            if (item?.qty === 1) {
                removeItem(item.id);
            } else {
                item.qty--;
            }
            state.shouldRefreshCart = true;
        },
        removeItem: (state, action) => {
            const removeItem = state.items.find(
                (item) => item.id === action.payload,
            );

            if (!removeItem) {
                return;
            }

            const remainItems = state.items.filter((item) => {
                if (item?.parentId === removeItem.id) {
                    return false;
                }

                return item.id !== action.payload;
            });

            state.items = remainItems;

            if (!state.items?.length) removeSessionKeyValue(CART);
            state.shouldRefreshCart = true;
        },
        removeCart: () => {
            removeSessionKeyValue(CART);
            return { ...initialState, shouldRefreshCart: true };
        },
        setCart: (state, action) => {
            state = action.payload;
            setSessionKeyValue(CART, action.payload);

            return state;
        },
        setTip: (state, action) => {
            state.tipAmount = action.payload;
            state.shouldRefreshCart = true;
        },
        setOrderItemType: (state, action) => {
            state.orderItemType = action.payload;
            state.items = [];

            if (action.payload === OrderItemTypeEnum.GIFT_CARD) {
                state.tipAmount = 0;
                state.giftCardAmount = 0;
                state.giftCardGroups = [];
                state.discountAmount = 0;
                state.discountIds = [];
                state.extraFee = 0;
            }

            state.shouldRefreshCart = true;
        },
        addGiftCardToCart: (state, action) => {
            if (action.payload.balance === 0) {
                const remainGiftCardGroups = state.giftCardGroups.filter(
                    (item) =>
                        item.giftCardCodeId !== action.payload.giftCardCodeId,
                );

                state.giftCardGroups = remainGiftCardGroups;
                state.giftCardAmount = remainGiftCardGroups.reduce(
                    (total, item) => total + item.balance,
                    0,
                );

                setSessionKeyValue(CART, state);

                return;
            }
            const itemGiftCard = state.giftCardGroups?.find(
                (item) => item.giftCardCodeId === action.payload.giftCardCodeId,
            );
            if (itemGiftCard) {
                state.giftCardAmount +=
                    action.payload.balance - itemGiftCard.balance;
                itemGiftCard.balance = action.payload.balance;
            } else {
                state.giftCardGroups.push({
                    ...action.payload,
                });
                state.giftCardAmount += action.payload.balance;
            }

            setSessionKeyValue(CART, state);
        },
        setNote: (state, action) => {
            const currentCart = cloneDeep(state);
            currentCart.note = action.payload;
            return currentCart;
        },
        refreshItem: (state, action) => {
            const currentCart = cloneDeep(state);

            const payload = action.payload as {
                cartItemId: string;
                cartItem: NCart.ICartItem;
            };

            if (!payload.cartItem) return currentCart;
            payload.cartItem.qty = payload.cartItem.qty || 1;

            let cartItems = currentCart.items || [];
            const existCartItemIndex = cartItems.findIndex(
                (item) => item.id === payload.cartItemId,
            );

            if (existCartItemIndex === -1) {
                cartItems = [...cartItems, payload.cartItem];
                currentCart.items = cartItems;
                return currentCart;
            }

            const refreshCartItem = {
                ...currentCart.items[existCartItemIndex],
                ...payload.cartItem,
            };

            cartItems.splice(existCartItemIndex, 1, refreshCartItem);
            currentCart.items = cartItems;

            return currentCart;
        },
        reInitCart: (state, action) => {
            setSessionKeyValue(CART, initialState);
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(calcGiftCard.fulfilled, (state, action: any) => {
            if (action?.payload) {
                state.giftCardAmount = action.payload.giftCardAmount;
                state.giftCardGroups = action.payload.giftCardGroups;
            }

            setSessionKeyValue(CART, state);
        });
    },
});
export const cartReducer = cartSlice.reducer;
export const cartSelector = (state: RootState): ICartState => state.cartReducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    removeCart,
    setCart,
    setTip,
    setOrderItemType,
    addGiftCardToCart,
    setNote,
    refreshItem,
    reInitCart,
} = cartSlice.actions;
