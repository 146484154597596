import moment, { now } from "moment";
import { toCentPrice } from "./format-price";
import { CurrencyType } from "../enums/currency-type.enum";
import dayjs from "dayjs";

export const validateEmail = (email: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase(),
    );
};

export const validatePassword = (pwd: string): boolean => {
    return /^(((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|((?=.*[0-9])(?=.*[a-z])(?=.*[<>{}/|;:.,~!?@#$%^=&*¿§«»ω⊙¤°℃℉€¥£¢¡®©^_+]))|((?=.*[a-z])(?=.*[<>{}/|;:.,~!?@#$%^=&*¿§«»ω⊙¤°℃℉€¥£¢¡®©^_+])(?=.*[A-Z]))|((?=.*[0-9])(?=.*[A-Z])(?=.*[<>{}/|;:.,~!?@#$%^=&*¿§«»ω⊙¤°℃℉€¥£¢¡®©^_+]))).{8,}$/.test(
        pwd,
    );
};

export const validatePhoneNumber = (phone: string): boolean => {
    return /\(\d{3}\)-\d{3}-\d{4}/g.test(phone?.toLowerCase());
};

export const validatePrice = (
    price: string,
    currency: CurrencyType | null = null,
): boolean => {
    if (currency) {
        switch (currency) {
            case CurrencyType.Usd:
                return /^\d+(\.\d{0,2})?$/.test(price);
            case CurrencyType.Vnd:
                return /^[0-9]*$/.test(price);
        }
    }
    return /^\d+(\.\d{0,2})?$/.test(price);
};

export const validateGcExpired = (expiredTime: string): boolean => {
    const second = dayjs(expiredTime).diff(dayjs(), "seconds");

    if (second > 0) {
        return false;
    }

    return true;
};

export const validateBalanceUse = (
    value: number,
    remainingBalance: number,
    grandTotal: number,
    giftCardAmount: number,
    balanceUse?: number,
): boolean => {
    if (grandTotal < 0) {
        return false;
    }

    const balance = toCentPrice(value);

    return (
        balance <= remainingBalance &&
        balance <= grandTotal - giftCardAmount + (balanceUse || 0) * 100
    );
};
