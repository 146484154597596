import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GUEST, USER_KEYS } from "../../common/constant";

import { RootState } from "../reducers";
import { removeSessionKeyValue, setSessionKeyValue } from "../../utils";
import { cloneDeep } from "lodash";
import { NUser } from "../../interfaces/components/user";

type UserState = NUser.IUser;

const initialState: UserState = {
    id: "",
    name: "",
    phoneNumber: "",
    gem: 0,
    locale: "vi",
};
const userSlice = createSlice({
    name: GUEST,
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<NUser.IUser>) => {
            let currentState = cloneDeep(state);
            if (action.payload) {
                setSessionKeyValue(USER_KEYS, action.payload);
                currentState = action.payload;
            }
            return currentState;
        },
        removeUser: () => {
            removeSessionKeyValue(USER_KEYS);
            return initialState;
        },
        setAddresses: (state, action: PayloadAction<NUser.IUserAddress[]>) => {
            const cloneState = cloneDeep(state);
            cloneState.addresses = action?.payload ?? [];
            return cloneState;
        },
        updateAddress: (state, action: PayloadAction<NUser.IUserAddress>) => {
            const cloneState = cloneDeep(state);
            let newAddresses = cloneState.addresses ?? [];
            let index = newAddresses.findIndex(
                (item) => item.id === action.payload?.id,
            );
            if (index >= 0) {
                newAddresses[index] = action.payload;
            } else {
                newAddresses = [...newAddresses, ...[action.payload]];
            }
            cloneState.addresses = newAddresses;
            return cloneState;
        },
        deleteAddress: (state, action: PayloadAction<string>) => {
            const cloneState = cloneDeep(state);
            let newAddresses = cloneState.addresses ?? [];
            let index = newAddresses.findIndex(
                (item) => item.id === action.payload,
            );
            if (index >= 0) {
                newAddresses.splice(index, 1);
            }
            cloneState.addresses = newAddresses;
            return cloneState;
        },
        setSelectedAddress: (
            state,
            action: PayloadAction<NUser.IUserAddress>,
        ) => {
            const cloneState = cloneDeep(state);
            cloneState.selectedAddress = action?.payload;
            return cloneState;
        },
    },
});

export const {
    setUser,
    removeUser,
    setAddresses,
    setSelectedAddress,
    updateAddress,
    deleteAddress,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
export const userSelector = (state: RootState): UserState => state.userReducer;
