import { checkIsInRangeTime } from ".";

export const checkValidOrderTime = (
    fromHour: string,
    toHour: string,
    orderStoppageTime: number,
): boolean => {
    if (fromHour && toHour) {
        const isValid = checkIsInRangeTime(fromHour, toHour, orderStoppageTime);
        return isValid;
    }

    return true;
};
