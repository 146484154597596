import { NShop } from "../../interfaces/components/shop";
import BaseApi from "./baseApi.service";
import Response from "@data-access/response/response";

export class ShopService extends BaseApi {
    private static instance: ShopService;
    private static store?: string;
    constructor(store?: string) {
        super();
        ShopService.store = store;
    }

    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (ShopService.store) {
            customHeader["x-tenant-slug"] = ShopService.store;
        }
        return customHeader;
    };

    public static getInstance(): ShopService {
        if (!ShopService.instance) {
            ShopService.instance = new ShopService();
        }
        return ShopService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new ShopService(store);
    }

    private readonly END_POINT = "v1/public";

    public async getPublicShops(): Promise<NShop.IPublicShop[]> {
        const result = await this.get<Response<NShop.IPublicShop[]>>(
            `${this.END_POINT}/shops`,
            {},
            this.customHeader(),
        );

        if (!result?.data) {
            return [];
        }

        return result?.data?.data;
    }

    public async getPublicShop(id: string): Promise<NShop.IPublicShop | null> {
        const result = await this.get<Response<NShop.IPublicShop>>(
            `${this.END_POINT}/shops/${id}`,
            {},
            this.customHeader(),
        );

        if (!result?.data) {
            return null;
        }

        return result?.data?.data;
    }

    public async verifyWorkingShop(
        dto: NShop.IVerifyWorkingShop,
    ): Promise<Response<Response<NShop.IVerifyWorkingShopResponse> | null>> {
        const result = await this.get<
            Response<NShop.IVerifyWorkingShopResponse>
        >(
            `${this.END_POINT}/shop/verify-shop-time`,
            dto as any,
            this.customHeader(),
        );

        return result || null;
    }
}
