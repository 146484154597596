import dayjs from "dayjs";
import { UnitEnum } from "../enums";
import { FORMAT_DATE_TIME } from "../common/constant";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "dayjs/locale/vi";
import "dayjs/locale/en";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

export const formatDateTime = (
    date: Date | string,
    format = FORMAT_DATE_TIME,
    locale?: string,
): string => {
    if (!date) return "";
    const formattedDate = dayjs(date).locale(`${locale}`).format(format);
    return formattedDate;
};
export const getNow = (format = FORMAT_DATE_TIME): string => {
    const now = dayjs().format(format);
    return now;
};

export const addTime = (
    time: number,
    unit: UnitEnum,
    format = FORMAT_DATE_TIME,
    date?: Date | string,
    locale?: string,
): string => {
    const dateFormat = date ? date : getNow();

    const addedTime = dayjs(dateFormat).add(time, unit).format(format);

    const formattedDate = dayjs(addedTime).locale(`${locale}`).format(format);

    return formattedDate;
};

export const getCurrentDateTime = (
    date: string,
): { dayName: string; hour: string } => {
    const newDate = dayjs(date);

    const dayName = dayjs(newDate).format("dddd");
    const hour = dayjs(newDate).format("HH:mm");

    return { dayName, hour };
};

export const checkIsInRangeTime = (
    fromDate: string,
    toDate: string,
    orderStoppageTime: number,
    date?: dayjs.Dayjs,
): boolean => {
    const checkDate = date ? date : dayjs();
    const orderStoppageDate = dayjs(toDate).subtract(
        orderStoppageTime,
        "minutes",
    );

    return (
        checkDate.isSameOrAfter(dayjs(fromDate)) &&
        checkDate.isSameOrBefore(dayjs(orderStoppageDate))
    );
};
