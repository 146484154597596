import Response from "@data-access/response/response";
import { OrderTransactionStatus } from "@enums/order-transacation-status.enum";
import { NDiscount, NOrderType, NCheckout, NShop } from "@interfaces";
import { PagingData } from "../../data-access/request-models/pagination.model";
import BaseApi from "./baseApi.service";

export class OrderService extends BaseApi {
    private static instance: OrderService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (OrderService.store) {
            customHeader["x-tenant-slug"] = OrderService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        OrderService.store = store;
    }

    public static getInstance(): OrderService {
        if (!OrderService.instance) {
            OrderService.instance = new OrderService();
        }
        return OrderService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new OrderService(store);
    }

    private readonly END_POINT = "v1/orders";

    public async placeOrder(request: NCheckout.IPlaceOrderRequest): Promise<{
        data: Partial<NCheckout.IPlaceOrderResponse> | null;
        err?: NShop.IVerifyWorkingShopResponse;
    }> {
        const result = await this.post<any>(
            `${this.END_POINT}/place-order`,
            request,
            {},
            this.customHeader(),
        );

        return {
            data: result?.data?.data || null,
            err: result?.data?.message,
        };
    }

    public async getOrder(
        id: string,
    ): Promise<Response<NOrderType.IOrderDetail> | null> {
        const result = await this.get<Response<NOrderType.IOrderDetail>>(
            `${this.END_POINT}/${id}`,
            {},
            this.customHeader(),
        );

        return result?.data;
    }

    public async payment(request: NCheckout.IPaymentRequest): Promise<{
        data: NCheckout.IPlaceOrderResponse | null;
        err?: NShop.IVerifyWorkingShopResponse;
    }> {
        const result = await this.put<any>(
            `${this.END_POINT}/payment`,
            request,
            {},
            this.customHeader(),
        );

        return {
            data: result?.data?.data || null,
            err: result?.data?.message,
        };
    }

    public async getMyOrder(
        query: NOrderType.IGetMyOrder,
    ): Promise<PagingData<NOrderType.IMyOrder> | null> {
        const data: { data: PagingData<NOrderType.IMyOrder> | null } =
            await this.get(`${this.END_POINT}/my-order`, query as any);

        return data?.data;
    }

    public async createOrder(
        request: NOrderType.ICreateOrder,
    ): Promise<Partial<NOrderType.IOrderResponse>> {
        const { data } = await this.post<any>(
            `${this.END_POINT}/create`,
            request,
            {},
            this.customHeader(),
        );

        return data?.data;
    }

    public async calcGiftCard(
        request: NCheckout.ICalcGiftCard,
    ): Promise<NOrderType.ICalcGiftCardResponse> {
        const result = await this.post<any>(
            `${this.END_POINT}/calc-gift-card`,
            request,
            {},
            this.customHeader(),
        );

        return result?.data?.data || null;
    }

    public async updateOrderItem(
        request: NOrderType.IUpdateOrder,
    ): Promise<NOrderType.IOrderResponse> {
        const { data } = await this.put<any>(
            `${this.END_POINT}/update-order-items`,
            request,
            {},
            this.customHeader(),
        );

        return data?.data || null;
    }

    public async updateInformation(
        request: NOrderType.IUpdateInformationOrder,
    ): Promise<NOrderType.IOrderResponse> {
        const { data } = await this.put<any>(
            `${this.END_POINT}/update-information-order`,
            { ...request },
            {},
            this.customHeader(),
        );

        return data?.data || null;
    }

    public async selectType(
        request: NCheckout.ISelectType,
    ): Promise<NOrderType.IOrderResponse> {
        const result = await this.put<any>(
            `${this.END_POINT}/select-type`,
            request,
            {},
            this.customHeader(),
        );

        return result?.data?.data || null;
    }

    public async applyCartRuleDiscount(
        request: NDiscount.IApplyDiscountRequest,
    ): Promise<NOrderType.IApplyCartRuleDiscountResponse> {
        const result = await this.put<any>(
            `${this.END_POINT}/apply-discount`,
            request,
            {},
            this.customHeader(),
        );

        return result?.data?.data || null;
    }

    public async syncPayment(query: Record<string, string>) {
        const result = await this.get<boolean>(
            `v1/webhook/onepay?${new URLSearchParams(query).toString()}`,
            {},
            this.customHeader(),
        );

        return result?.data;
    }

    public async getPaymentResult(
        id: string,
    ): Promise<Response<OrderTransactionStatus> | null> {
        const result = await this.get<Response<OrderTransactionStatus>>(
            `v1/orders/payment-status/${id}`,
            {},
            this.customHeader(),
        );

        return result.data;
    }

    public async updateShippingMethod(
        request: NDiscount.IUpdateShippingMethodRequest,
    ): Promise<NOrderType.IOrderResponse> {
        const result = await this.put<any>(
            `${this.END_POINT}/shipping-method`,
            request,
            {},
            this.customHeader(),
        );

        return result?.data?.data || null;
    }
}
