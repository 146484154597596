import BaseApi from "./baseApi.service";
import { NSetting } from "../../interfaces/components/setting";
import { BannerSettingDto } from "@data-access/models/banner-setting.model";

export class SettingService extends BaseApi {
    private static instance: SettingService;
    private static store?: string;
    constructor(store?: string) {
        super();
        SettingService.store = store;
    }

    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (SettingService.store) {
            customHeader["x-tenant-slug"] = SettingService.store;
        }
        return customHeader;
    };

    public static getInstance(): SettingService {
        if (!SettingService.instance) {
            SettingService.instance = new SettingService();
        }
        return SettingService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new SettingService(store);
    }

    private readonly END_POINT = {
        setting: "v1/public/setting",
        images: "v1/resource/images",
        bannerSetting: "v1/setting/banners",
    };

    public async getSetting(): Promise<NSetting.IGeneralInfo | null> {
        const { data, ...rest } = await this.get<any>(
            this.END_POINT.setting,
            {},
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }

        if (rest.messages === "Error" && rest.status === false) {
            throw new Error("site_is_blocked");
        }
        return null;
    }

    public async getDefaultImages(): Promise<string[] | null> {
        const { data } = await this.get<{ data: string[] }>(
            this.END_POINT.images,
            {},
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }
        return null;
    }
}
