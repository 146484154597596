import { NSearch } from "@interfaces/components/search";

import { v4 as uuidv4 } from "uuid";

export const SEARCH_KEY = "search_key";
export const SEARCH_TEXT_LENGTH_LIMIT = 5;

export const logSearch = (filter: string) => {
    let keyList: NSearch.ISearch[] = [];
    const searchedValue = localStorage.getItem(SEARCH_KEY);
    if (searchedValue) {
        keyList = [...JSON.parse(searchedValue)];
        if (keyList?.length > SEARCH_TEXT_LENGTH_LIMIT) {
            keyList = keyList.slice(0, SEARCH_TEXT_LENGTH_LIMIT);
        }
    }

    keyList.unshift({
        id: uuidv4(),
        value: filter,
    });
    const newList = JSON.stringify(keyList);
    localStorage.setItem(SEARCH_KEY, newList);
};
