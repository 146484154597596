import { NFusoft } from "@interfaces/components/fusoft";
import { RootState } from "@redux/reducers";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SettingService } from "@services/api/setting.service";
import { cloneDeep } from "lodash";

const initialState: NFusoft.IFuSoftState = {
    isFuSoftManagePage: false,
    isRefetch: false,
    isToggleShowCart: false,
    isNavigating: false,
    defaultImages: [],
};

const settingService = SettingService.getInstance();
export const getDefaultImages = createAsyncThunk(
    "fuSoft/getDefaultImages",
    async (_, thunkAPI) => {
        try {
            const defaultImages = await settingService.getDefaultImages();
            return defaultImages;
        } catch (err) {
            return thunkAPI.rejectWithValue(null);
        }
    },
);

const fuSoftSlice = createSlice({
    name: "fuSoft",
    initialState,
    reducers: {
        checkFuSoftManagePage: (currState, action: PayloadAction<boolean>) => {
            currState.isFuSoftManagePage = action.payload;
            return currState;
        },
        refetchManageOrders: (currState, action: PayloadAction<boolean>) => {
            currState.isRefetch = action.payload;
            return currState;
        },
        toggleShowCart: (currState, action: PayloadAction<boolean>) => {
            currState.isToggleShowCart = action.payload;
            return currState;
        },
        toggleNavigating: (currState, action: PayloadAction<boolean>) => {
            currState.isNavigating = action.payload;
            return currState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getDefaultImages.fulfilled,
            (state, action: unknown) => {
                const currState = cloneDeep(state);
                const defaultImages =
                    (action as { payload: string[] }).payload || [];
                return { ...currState, defaultImages };
            },
        ),
            builder.addCase(getDefaultImages.rejected, (state, _) => {
                const currState = cloneDeep(state);
                return { ...currState, defaultImages: [] };
            });
    },
});

export const fuSoftReducer = fuSoftSlice.reducer;

export const fuSoftSelector = (state: RootState): NFusoft.IFuSoftState =>
    state.fuSoftReducer;

export const {
    checkFuSoftManagePage,
    refetchManageOrders,
    toggleShowCart,
    toggleNavigating,
} = fuSoftSlice.actions;
