import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GUEST } from "../../common/constant";

import { IGuest, ILatLng, IOrderAddressDto } from "../../interfaces";
import { RootState } from "../reducers";
import { removeSessionKeyValue, setSessionKeyValue } from "../../utils";
import _ from "lodash";

const initialState: IGuest = {
    customerName: "",
    numberOfPeople: 1,
    phoneNumber: null,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    identityId: null,
    ts: "",
    host: "",
    tenantId: "",
    username: "",
    shopId: "",
    fuid: "",
    pcName: "",
    ipPc: "",
    data: "",
    userId: "",
};
const guestSlice = createSlice({
    name: GUEST,
    initialState,
    reducers: {
        setGuest: (_, action: PayloadAction<IGuest>) => {
            if (action.payload) {
                setSessionKeyValue(GUEST, action.payload);
            }
            return { ...action.payload };
        },
        removeGuest: () => {
            removeSessionKeyValue(GUEST);
            return initialState;
        },
        setGuestAddress: (state, action: PayloadAction<IOrderAddressDto>) => {
            const clonedState = _.cloneDeep(state);
            const address = action.payload;

            clonedState.address = address;
            setSessionKeyValue(GUEST, clonedState);
            return clonedState;
        },
        setGuestLatLng: (state, action: PayloadAction<ILatLng>) => {
            let clonedState = _.cloneDeep(state);
            clonedState = {
                ...clonedState,
                ...action.payload,
            };

            setSessionKeyValue(GUEST, clonedState);
            return clonedState;
        },
    },
});

export const { setGuest, removeGuest, setGuestAddress, setGuestLatLng } =
    guestSlice.actions;
export const guestReducer = guestSlice.reducer;
export const guestSelector = (state: RootState): IGuest => state.guestReducer;
