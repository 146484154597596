import { CurrencyType } from "@enums/currency-type.enum";
import { NCurrency } from "../interfaces/components/currency";

const FIX_CHARACTERS = 2;
export const formatPriceBaseOnCurrency = (
    price: number,
    config: NCurrency.IConfigCurrency | null = null,
): string => {
    const formatter = new Intl.NumberFormat(config?.localeString ?? "en-US", {
        style: "currency",
        currency: config?.currencyFormat ?? "USD",
    }).format(price / 100 || 0);
    const formatVND = formatter.replaceAll(".", ",");
    return config?.symbol === "đ" ? formatVND : formatter;
};

export const formatValuePrice = (
    price: number,
    config: NCurrency.IConfigCurrency | null = null,
): string => {
    if (!price) {
        price = 0;
    }
    let numb = (price / 100).toString();
    if (config?.currencyFormat === "USD") {
        numb = (price / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }

    return numb;
};

export const formatPrice = (price: number, currencySymbol = "$"): string => {
    if (!price) {
        price = 0;
    }

    const numb = (price / 100).toFixed(2);

    return Number(numb).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};

export const toCentPrice = (price: number): number => {
    return price ? Math.round(Number(price * 100)) : 0;
};

export const toDollarPrice = (price: number): number => {
    return price ? price / 100 : 0;
};

export const getTaxAmount = (
    price: number,
    taxPercent: number,
    isFormat = true,
): string | number => {
    const tax = price * (taxPercent / 100);
    return isFormat ? formatPrice(tax) : tax;
};

export const getOriginalPrice = (
    price: number,
    taxPercent: number,
    isFormat = true,
): string | number => {
    const tax = Number(getTaxAmount(price, taxPercent, false));
    const originalPrice = price - tax;
    return isFormat ? formatPrice(originalPrice) : originalPrice;
};

export const modifyFloatPrice = (value: string): string => {
    const splitNumber = value.split(".");
    if (splitNumber[1]?.length) {
        const decimal = splitNumber[1].slice(0, FIX_CHARACTERS);

        const natural = splitNumber[0] ?? 0;
        const mergeNumber = [natural, decimal].join(".");
        return mergeNumber;
    }

    return value;
};

export const formatPriceString = (amount: string | number): string => {
    if (isNaN(Number(amount))) return "";
    return Number(amount) % 1 === 0 ? `${Number(amount)}.00` : `${amount}`;
};

export const dollarToCent = (price: number | string | undefined): number => {
    const floatPrice: any = price ? parseFloat(price.toString()) : 0;
    return floatPrice
        ? Math.round(floatPrice.toFixed(FIX_CHARACTERS) * 100)
        : 0;
};

export const calculatePriceIncludedTax = (
    price: number,
    tax: number,
): number => {
    if (!price || !tax) {
        return price;
    }

    return Math.round(+((price * 100) / (100 + tax)).toFixed(FIX_CHARACTERS));
};

export const calculateIncludedTax = (price: number, tax: number): number => {
    if (!price) {
        return 0;
    }

    return Math.round(
        +(price - (price * 100) / (100 + tax)).toFixed(FIX_CHARACTERS),
    );
};
