import { filter } from "fuzzy";
import { normalizeString } from "./string";

/**
 * Check a number is float or not
 */
export const isFloat = (number: number): boolean => {
    return Number(number) === number && number % 1 !== 0;
};

/**
 * Small devices (mobile, 768px and down)
 */
export const isSmallScreen = (): boolean => {
    if (typeof window === "undefined") {
        return false;
    }
    if (typeof window === "undefined") {
        return false;
    }

    const width = window.innerWidth || document.body.clientWidth;
    return width < 768;
};

/**
 * Medium devices (tablets, 992px and down)
 */
export const isMediumScreen = (): boolean => {
    if (typeof window === "undefined") {
        return false;
    }
    const width = window.innerWidth || document.body.clientWidth;
    return width >= 768 && width < 992;
};

/**
 * Large devices (desktops, 1200px and up)
 */
export const isLargeScreen = (): boolean => {
    if (typeof window === "undefined") {
        return false;
    }
    const width = window.innerWidth || document.body.clientWidth;
    return width >= 992;
};

export const MINIMUM_VND = 10000;

export const getItemImgSrc = ({
    images,
    cdn,
    name,
    photo,
    isUrl,
    itemNames,
}: {
    images: string[];
    name: string;
    cdn: string;
    photo?: string;
    isUrl?: boolean;
    itemNames?: string[];
}) => {
    /*
    * *
    priority 1: photo of item which was uploaded from portal
    priority 2: photo which is default image of item name 
    priority 3: default item image 
    */
    if (photo) {
        return isUrl ? photo : `${cdn}/${photo}`;
    }

    const filterImageNames = images
        .map((item) => item.split(".")?.[0])
        .filter((item) => item);

    const itemFilterName = name.split(" ").join("_").toLowerCase();
    const normalizeItemName = normalizeString(itemFilterName);
    itemNames && itemNames.push(normalizeItemName);
    const scoredItems = filter(normalizeItemName, filterImageNames);
    if (scoredItems?.length > 0) {
        //get item has max score
        const matchItem = scoredItems.reduce((prev, curr) => {
            return prev.score >= curr.score ? prev : curr;
        }, scoredItems[0]);

        return `${cdn}/images/${images[matchItem.index]}`;
    }
    return "/images/default-item.png";
};
