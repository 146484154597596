import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { RootState } from "../reducers";
import { NMarket } from "../../interfaces/components/market";
import { setSessionKeyValue } from "../../utils";
import { STORE_CARTS } from "../../common/constant";

type MarketState = NMarket.IState;
const initialState: MarketState = {
    storeCarts: [],
};

const marketSlice = createSlice({
    name: "market",
    initialState,
    reducers: {
        setCurrentStore: (state, action) => {
            const currentState = cloneDeep(state);
            currentState.currentStore = action.payload;
            return currentState;
        },
        selectBusiness: (state, action) => {
            const currentState = cloneDeep(state);
            currentState.selectedBusiness = action.payload;
            return currentState;
        },
        selectCategory: (state, action) => {
            const currentState = cloneDeep(state);
            currentState.selectedCategory = action.payload;
            return currentState;
        },
        toggleOpenCart: (currState, action) => {
            currState.isOpenStoreCart = action.payload;
            return currState;
        },
        updateFromSession: (state, action) => {
            const currentState = cloneDeep(state);
            const payload = action?.payload;
            currentState.storeCarts = payload ?? [];
            return currentState;
        },
        addStoreCart: (state, action) => {
            const currentState = cloneDeep(state);
            const payload = action?.payload;
            const existCart = currentState.storeCarts?.find(
                (cart) => cart.store === payload.store,
            );
            if (!existCart) {
                currentState.storeCarts?.push(payload);
            } else {
                existCart.cart = payload.cart;
            }
            setSessionKeyValue(STORE_CARTS, currentState.storeCarts);
            return currentState;
        },
        removeStoreCart: (state, action) => {
            const currentState = cloneDeep(state);
            const payload = action?.payload;
            currentState.storeCarts = currentState.storeCarts?.filter(
                (c) => c.store !== payload,
            );
            setSessionKeyValue(STORE_CARTS, currentState.storeCarts);
            return currentState;
        },
        emptyStoreCart: (state, _) => {
            const currentState = cloneDeep(state);
            currentState.storeCarts = [];
            setSessionKeyValue(STORE_CARTS, currentState.storeCarts);
            return currentState;
        },
    },
});
export const marketReducer = marketSlice.reducer;
export const marketSelector = (state: RootState): MarketState =>
    state.marketReducer;

export const {
    setCurrentStore,
    selectBusiness,
    selectCategory,
    toggleOpenCart,
    addStoreCart,
    removeStoreCart,
    updateFromSession,
} = marketSlice.actions;
