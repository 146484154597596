import { Table } from "../../data-access/models/table.model";
import { NTable } from "../../interfaces/components/table";
import BaseApi from "./baseApi.service";

export class TableService extends BaseApi {
    private static instance: TableService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (TableService.store) {
            customHeader["x-tenant-slug"] = TableService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        TableService.store = store;
    }

    public static getInstance(): TableService {
        if (!TableService.instance) {
            TableService.instance = new TableService();
        }
        return TableService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new TableService(store);
    }
    private readonly END_POINT = "v1/public/table";

    public async getTable(id: string): Promise<Table | null> {
        const { data } = await this.get<any>(
            `${this.END_POINT}/${id}`,
            {},
            this.customHeader(),
        );
        return data;
    }

    public async helpTable(dto: NTable.IHelpTable): Promise<void> {
        await this.put(`${this.END_POINT}/help`, dto, {}, this.customHeader());
    }
}
